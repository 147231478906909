import { SKYWEB_HOME_BUNDLE_LINK_TEXT } from "./config";

export const sendAnalytics = async (
    event_name: string,
    extraParam: any,
  ): Promise<void> => {
    const commonParams = {
      title: document.title,            // Page title
      url: window.location.href,        // Full URL of the page
      path: window.location.pathname,   // Path (e.g., /home, /about)
      referrer: document.referrer,      // Referrer (if available)
    };
    const segmentSent = new Promise<void>((resolve, reject) => {
      window.analytics?.track(
        event_name,
        { ...commonParams, ...extraParam },
      );
      resolve();
    });
  
    return segmentSent.then(() => {});
  };
  

  export const logImpression = (impressionData: any): void => {
    // if (impressionData.impression.feature === SKYWEB_HOME_BUNDLE_LINK_TEXT) {
    //   if (window.gtag) {
    //     window.gtag('event', 'split_impression', {
    //       'split_name': impressionData.impression.feature,
    //       'split_treatment': impressionData.impression.treatment,
    //       'split_label': impressionData.impression.label,
    //       'split_changeno': impressionData.impression.changeNumber,
    //       'split_time': impressionData.impression.time
    //       // can add additional properties here as well
    //     });
    //   }
    // }
  };
  